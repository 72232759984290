<template >

 
  <div class="bgimg">
  <p style="color:white;">What it is about: </p>
  <p></p>
  <p></p>
    <p
        v-tooltip="{
                    
                    theme: {
                        placement: 'bottom',

                    },

                }"
    >
                  <span v-tooltip="'Add your tango request for a partner \n with a desirable tango experience in years, \n choose month from calendar.\n Choose place country(required), \n region, city where you are going to practice/milonga.\n After you added your request tango dancers \n can see your request and email \n you or call you if you left your phone. \n You can search tango dancers in country(required) \n and city/region who put themselves \n available(checkbox while signing up) \n Or search tango dancers by location \n and with checkbox checked for \n search dancers requests for desirable month'" :class="newlineCSS"> 
<span ><strong style="color: red">Dancers </strong></span>
        </span>

    </p>
  <p></p>
  <p></p>     
    <p
        v-tooltip="{
                    
                    theme: {
                        placement: 'bottom',

                    },

                }"
    >
                  <span v-tooltip="'Add tango place in your profile, if it is not yet \n been added before by other tango dancer. \n Search place before adding, \n by country, city, name of place and street. \n To search tango places: country(required) and city/region, \n street, name of place. \n  To leave review of tango place: click on \n the name of tango place and then on the link. \n Put your review with a description.'" :class="newlineCSS"> 
<span ><strong style="color: blue">Tango Places </strong></span>
        </span>        
    </p> 
    
  </div>
<div>
        <p v-tooltip="`${$t('pethome_tip')}`">
        
        <a href="https://pethome.krucha.com" target="_blank" rel="noopener"  ><strong strong style="color: blue">Temporary home for pets </strong></a><span style="color:white;"> You may like it</span>
      </p> 
</div>      
</template>

<script>
import i18n from '@/i18n'
import { directive } from 'vue-tippy'
export default {
  name: "About",
  directives: {
    tippy: directive,
  },  
  data() {
    
    
    return {
      
      newlineCSS: "newlineStringStyle",
      imgHome: i18n.global.t('home_img')
      
    }
  },
  methods: {
    getText() {
 
      
    }
  },  
  created () {
    console.log("created locale", this.$i18n.locale)
  }
     
};
</script>
<style>
.bgimg {
    background-image: url('/assets/tango-gray.png');
    //background-image: url('/assets/tango-yellow.png');
    height: 639px;
    width: 480px;
    background-repeat: no-repeat;
    fetchpriority: high;
}
.p {
  color: black;
  font-size: 17px;
}

.data-v-tooltip {
  
  --v-tooltip-background-color: #938ba1;
  --v-tooltip-left: 200px !important;
  --v-tooltip-top: 1% !important;
  
}
.newlineStringStyle {
    
    white-space: pre-wrap;
}
</style>