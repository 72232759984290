<template>
  <div class="home">
    <About />
  </div>
</template>

<script>
import About from '@/views/About.vue'

export default {
  name: 'Home',
  components: {
    About,
  },
}
</script>
